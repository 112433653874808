import api from "http";

export default class AuthService{
    static async login(
        login,
        password
      ) {
        const response = await api.post("/admin/login", { login, password });
        return response
      }
       
      static async logout() {
        return api.post("/admin/logout");
      }
    
      static async refresh() {
        return api.get("/admin/refresh", {withCredentials: true})
      }
}