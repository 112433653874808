import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import {
    Flex,
    Button,
    Checkbox,
    SimpleGrid,
    Heading,
    Spacer,
    Divider,
    useToast,
    Input,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Calendar from "./components/Calendar";
import WinnersTable from "./components/WinnersTable";

function Raffle() {
    const toast = useToast();
    const router = useHistory();
    const [loading, setLoading] = useState({
        raffleBtn: false,
        confirmWinnersBtn: false,
    });
    const [raffleData, setRaffleData] = useState({
        raffleWeekStart: "",
        raffleWeekEnd: "",
        raffleWeek: "",
        grandPrizeIncludes: false,
    });
    const [flag, setFlag] = useState(true);
    const [raffleName, setRaffleName] = useState("Выберите тип розыгрыша");
    const [prevWinners, setPrevWinners] = useState({});

    function openUserCard(id) {
        router.push(`/dashboard/user-card/${id}`);
    }
    function openQrCard(id) {
        router.push(`/dashboard/check-card/${id}`);
    }

    function setRaffleType(e) {
        const target = e.target.textContent;

        setRaffleName(target);
        if (target === "Финальный") {
            setFlag(false);
            setRaffleData({ ...raffleData, grandPrizeIncludes: true });
        }
        if (target === "Недельный") {
            setFlag(false);
            setRaffleData({ ...raffleData, grandPrizeIncludes: false });
        }
    }

    async function getPrevWinners() {
        try {
            if (!raffleData.raffleWeekStart && !raffleData.raffleWeekEnd)
                throw new Error("Неверные настройки периода");
            if (!raffleData.raffleWeek) throw new Error("введите название розыгрыша");

            setLoading({ ...loading, raffleBtn: true });

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/toms-raffle/prev-raffle-winners`,
                raffleData
            );

            setPrevWinners(response.data.data);
            setLoading({ ...loading, raffleBtn: false });

            localStorage.setItem("prevWinners", JSON.stringify(response.data.data));
            localStorage.setItem("raffleWeek", JSON.stringify(raffleData.raffleWeek));

            setFlag(true);
        } catch (error) {
            console.error(error.message);

            toast({
                title: `Неверные настройки розыгрыша или нет подходящих чеков`,
                status: "error",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
            setLoading({ ...loading, raffleBtn: false });
        }
    }

    async function confirmWinners() {
        try {
            setLoading({ ...loading, confirmWinnersBtn: true });

            const raffleWeek = JSON.parse(localStorage.getItem("raffleWeek"));

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/toms-raffle/fill-raffle-db`,
                { ...prevWinners, raffleWeek: raffleWeek }
            );

            setLoading({ ...loading, confirmWinnersBtn: false });

            toast({
                title: `Розыгрыш проведен успешно!`,
                status: "success",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
            clearRaffle();
        } catch (error) {
            console.error(error.message);

            toast({
                title: `Ошибка при сохранении в базу данных`,
                status: "error",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
            setLoading({ ...loading, confirmWinnersBtn: false });
        }
    }

    function clearRaffle() {
        localStorage.removeItem("prevWinners");
        setPrevWinners({});
        window.location.reload();
    }

    useEffect(() => {
        const getWinnersFromLS = localStorage.getItem("prevWinners");

        if (getWinnersFromLS === null) {
            setPrevWinners({});
            return;
        }
        const response = JSON.parse(getWinnersFromLS);
        setPrevWinners(response);
    }, []);

    return (
        <Flex direction='column' pt={{ sm: "125px", md: "75px" }}>
            <SimpleGrid columns={{ sm: "1", md: "2" }} gap='20px' mb='24px'>
                <Card w='100%'>
                    <CardHeader display='flex' justifyContent='center' mb='10px'>
                        <Heading size='md'>Тип розыгрыша</Heading>
                    </CardHeader>

                    <Divider />
                    <Spacer />

                    <CardBody>
                        <Flex justify='center' direction='column' w='100%' mt='20px' gap='15px'>
                            <Menu>
                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                    {raffleName}
                                </MenuButton>
                                <MenuList onClick={setRaffleType}>
                                    <MenuItem>Недельный</MenuItem>
                                    <MenuItem>Финальный</MenuItem>
                                </MenuList>
                            </Menu>

                            <Input
                                isDisabled={flag}
                                placeholder='Введите название'
                                onChange={(e) =>
                                    setRaffleData({
                                        ...raffleData,
                                        raffleWeek: e.target.value,
                                    })
                                }
                            />
                        </Flex>
                    </CardBody>
                </Card>

                <Card w='100%'>
                    <CardHeader display='flex' justifyContent='center' mb='10px'>
                        <Heading size='md'>Период розыгрыша</Heading>
                    </CardHeader>

                    <Divider />
                    <Spacer />

                    <CardBody display='flex' justifyContent='center' h='100%' alignItems='center'>
                        <Flex direction='column' gap='10px'>
                            <Calendar setRaffleData={setRaffleData} raffleData={raffleData} />
                        </Flex>
                    </CardBody>
                </Card>
            </SimpleGrid>

            <Button
                colorScheme='linkedin'
                mb='20px'
                onClick={getPrevWinners}
                isLoading={loading.raffleBtn}
            >
                Провести розыгрыш
            </Button>

            <Card my='25px'>
                <CardHeader display='flex' justifyContent='center'>
                    <Heading>Предварительные победители розыгрыша</Heading>
                </CardHeader>

                <CardBody>
                    <WinnersTable
                        data={prevWinners}
                        openUserCard={openUserCard}
                        openQrCard={openQrCard}
                    />
                </CardBody>
            </Card>

            <Flex gap='20px'>
                <Button
                    colorScheme='green'
                    isLoading={loading.confirmWinnersBtn}
                    onClick={confirmWinners}
                >
                    Подтвердить
                </Button>

                <Button onClick={clearRaffle} colorScheme='red'>
                    Отменить
                </Button>
            </Flex>
        </Flex>
    );
}

export default Raffle;
