import React, { useState, useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import UserComponents from "./UserComponent";

function UserCard() {
    const { id } = useParams();
    const [user, setUser] = useState([]);

    async function getUser() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/qr/userchecks/${id}`);
            console.log(res.data.data);
            setUser(res.data.data);
        } catch (error) {
            console.error(error.message);
        }
    }

    useEffect(() => {
        getUser();
    }, [id]);

    return (
        <Flex direction='column' pt={{ sm: "125px", md: "75px" }}>
            <Flex
                direction={{ sm: "column", md: "row" }}
                justify='space-between'
                align='center'
                w='100%'
                mb='24px'
            >
                <Text fontSize='4xl' color='grey' fontWeight='bold'>
                    Чеки пользователя
                </Text>
            </Flex>
            <Flex>
                <UserComponents data={user} />
            </Flex>
        </Flex>
    );
}

export default UserCard;
