// import Tables from "views/Dashboard/Tables.js";
import { CartIcon, DocumentIcon, HomeIcon, PersonIcon, StatsIcon } from "components/Icons/Icons";
import SignInBasic from "views/Authentication/SignIn/SignInBasic.js";
import AllCheck from "views/ManualModeration/AllCheck";
import WaitCheck from "views/ManualModeration/WaitCheck";
import CheckCard from "views/ManualModeration/components/CheckCard";
import AllUsers from "views/Users";
import UserCard from "views/Users/components/UserCard";
import Stat from "views/Statistics/index";
import Raffle from "views/Raffle/LotteryBlock";
import WinnersBlock from "views/Raffle/WinnersBlock";

const dashRoutes = [
    {
        name: "Чеки",
        path: "/dashboard",
        icon: <StatsIcon color='inherit' />,
        authIcon: <StatsIcon color='inherit' />,
        collapse: true,
        items: [
            {
                name: "Все чеки",
                path: "/all-checks",
                component: AllCheck,
                layout: "/dashboard",
            },
            {
                name: "Ручная модерация",
                path: "/manual-moderation",
                component: WaitCheck,
                layout: "/dashboard",
            },
            {
                name: "Чек",
                path: "/check-card/:id",
                hidden: true,
                component: CheckCard,
                layout: "/dashboard",
            },
        ],
    },

    {
        name: "Пользователи",
        path: "/dashboard",
        icon: <PersonIcon color='inherit' />,
        authIcon: <PersonIcon color='inherit' />,
        collapse: true,
        items: [
            {
                name: "Все пользователи",
                path: "/users",
                component: AllUsers,
                layout: "/dashboard",
            },
            {
                name: "Чеки пользователя",
                hidden: true,
                path: "/user-card/:id",
                component: UserCard,
                layout: "/dashboard",
            },
        ],
    },

    {
        name: "Статистика",
        path: "/dashboard",
        icon: <StatsIcon color='inherit' />,
        authIcon: <StatsIcon color='inherit' />,
        collapse: true,
        items: [
            {
                name: "Статистика по промоакции",
                path: "/statistic",
                component: Stat,
                layout: "/dashboard",
            },
        ],
    },

    {
        name: "Розыгрыш",
        path: "/dashboard",
        icon: <StatsIcon color='inherit' />,
        authIcon: <StatsIcon color='inherit' />,
        collapse: true,
        items: [
            {
                name: "Проведение розыгрыша",
                path: "/raffle",
                component: Raffle,
                layout: "/dashboard",
            },
            {
                name: "Победители",
                path: "/winners-block",
                component: WinnersBlock,
                layout: "/dashboard",
            },
        ],
    },

    {
        name: "Basic",
        icon: <HomeIcon color='inherit' />,
        authIcon: <HomeIcon color='inherit' />,
        hidden: true,
        component: SignInBasic,
        path: "/",
        layout: "/auth",
    },
];

export default dashRoutes;
