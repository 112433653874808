import React from "react";
import { Table, Tbody, Th, Thead, Tr, Td, Flex, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

const WinnersTable = ({ data, openUserCard, openQrCard }) => {
    return (
        <Card overflowX='scroll'>
            <CardBody>
                <Table variant='striped' size='sm'>
                    <Thead flex='column' justifyContent='space-between'>
                        <Tr color='gray.500'>
                            <Th color='gray.500'>Id пользователя</Th>
                            <Th color='gray.500'>Email пользователя</Th>
                            <Th color='gray.500'>ФИО</Th>
                            <Th color='gray.500'>Номер телефона</Th>
                            <Th color='gray.500'>Статус пользователя</Th>
                            <Th color='gray.500'>Чек</Th>
                            <Th color='gray.500'>Период</Th>
                            <Th color='gray.500'>Приз</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {data.map((row) => (
                            <Tr key={row.qrcode_id}>
                                <Td>
                                    <Flex align='center'>
                                        <Text
                                            w='min-content'
                                            role='button'
                                            fontSize='md'
                                            fontWeight='bold'
                                            onClick={() => openUserCard(row.accounts_id)}
                                        >
                                            {row.accounts_id || "-"}
                                        </Text>
                                    </Flex>
                                </Td>

                                <Td>
                                    <Flex align='center'>
                                        <Text fontSize='md' fontWeight='bold'>
                                            {row.email || "-"}
                                        </Text>
                                    </Flex>
                                </Td>

                                <Td>
                                    <Flex align='center'>
                                        <Text color='gray.400' fontSize='md'>
                                            {row.fio || "-"}
                                        </Text>
                                    </Flex>
                                </Td>

                                <Td>
                                    <Flex align='center'>
                                        <Text fontSize='md' fontWeight='bold'>
                                            {row.phone || "-"}
                                        </Text>
                                    </Flex>
                                </Td>

                                <Td>
                                    <Flex align='center'>
                                        <Text fontSize='md' fontWeight='bold'>
                                            {row.status || "-"}
                                        </Text>
                                    </Flex>
                                </Td>

                                <Td>
                                    <Flex align='center' w='min-content'>
                                        <Text
                                            color='gray.400'
                                            fontSize='md'
                                            role='button'
                                            onClick={() => openQrCard(row.accounts_id)}
                                        >
                                            {row.qrcode_id || "-"}
                                        </Text>
                                    </Flex>
                                </Td>

                                <Td>
                                    <Flex align='center'>
                                        <Text color='gray.400' fontSize='md'>
                                            {row.raffle_type || "-"}
                                        </Text>
                                    </Flex>
                                </Td>

                                <Td>
                                    <Flex align='center'>
                                        <Text color='gray.400' fontSize='md'>
                                            {row.prize || "-"}
                                        </Text>
                                    </Flex>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default WinnersTable;
