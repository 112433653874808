import React from "react";
import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import WinnersTemplateTable from "./WinnersTemplateTable";

const WinnersTable = ({ data, openUserCard, openQrCard }) => {
    const { winners1000, winners3000, grandPrizeWinner } = data;

    return (
        <Card overflowX='scroll'>
            <CardBody>
                <Table variant='striped' size='sm'>
                    <Thead flex='column' justifyContent='space-between'>
                        <Tr color='gray.500'>
                            <Th color='gray.500'>Id пользователя</Th>
                            <Th color='gray.500'>Email пользователя</Th>
                            <Th color='gray.500'>ФИО</Th>
                            <Th color='gray.500'>Номер телефона</Th>
                            <Th color='gray.500'>Чек</Th>
                            <Th color='gray.500'>Приз</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        <WinnersTemplateTable
                            data={winners1000 || []}
                            openQrCard={openQrCard}
                            openUserCard={openUserCard}
                            prize={"1000 RUB"}
                        />
                        <WinnersTemplateTable
                            data={winners3000 || []}
                            openQrCard={openQrCard}
                            openUserCard={openUserCard}
                            prize={"3000 RUB"}
                        />
                        <WinnersTemplateTable
                            data={grandPrizeWinner || []}
                            openQrCard={openQrCard}
                            openUserCard={openUserCard}
                            prize={"150 000 RUB"}
                        />
                    </Tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default WinnersTable;
