import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import {
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    SimpleGrid,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Stack,
} from "@chakra-ui/react";
import { SearchIcon, ChevronDownIcon, ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { WalletIcon, GlobeIcon, DocumentIcon, CartIcon } from "components/Icons/Icons";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CheckTable from "./CheckTable";
import StatComponent from "./StatComponent";

function CheckData({ data }) {
    const router = useHistory();
    const [checks, setChecks] = useState([]);
    const [info, setInfo] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [limitOnPage, setLimitOnPage] = useState(5);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showAll, setShowAll] = useState(false);

    async function exportToExcel() {
        const fileName = "allChecks";
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(checks);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    function openCard(id) {
        router.push(`/dashboard/check-card/${id}`);
    }

    function allUsers(e) {
        if (e.target.textContent === "Все") {
            setShowAll(true);
            setLimitOnPage(e.target.textContent);
        } else {
            setShowAll(false);
            setLimitOnPage(e.target.textContent);
        }
    }

    async function getWaitCHecks() {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/qr/get-all-wait-qrcodes`
            );
            setChecks(res.data.data);

            const totalChecks = res.data.data.length;
            const totalPages = Math.ceil(totalChecks / limitOnPage);

            setTotalPages(totalPages);
        } catch (error) {
            console.error(error.message);
        }
    }

    async function getAllCHecks() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/qr`);

            setChecks(res.data.data);

            const totalChecks = res.data.data.length;
            const totalPages = Math.ceil(totalChecks / limitOnPage);

            setTotalPages(totalPages);
        } catch (error) {
            console.error(error.message);
        }
    }

    async function statInfo() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/qr/stat`);
            setInfo(res.data.data);
        } catch (error) {
            console.error(error.message);
        }
    }

    useEffect(() => {
        if (data) {
            getWaitCHecks();
        } else getAllCHecks();
    }, [limitOnPage, page]);

    useEffect(() => {
        statInfo();
    }, []);

    const filteredChecks = useMemo(() => {
        const filteredData = checks?.filter(
            (item) =>
                String(item.id).trim().toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                String(item.create_date)
                    .trim()
                    .toLowerCase()
                    .includes(searchValue.trim().toLowerCase()) ||
                String(item.email)
                    .trim()
                    .toLowerCase()
                    .includes(searchValue.trim().toLowerCase()) ||
                String(item.fio).trim().toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                String(item.phone)
                    .trim()
                    .toLowerCase()
                    .includes(searchValue.trim().toLowerCase()) ||
                String(item.summary)
                    .trim()
                    .toLowerCase()
                    .includes(searchValue.trim().toLowerCase()) ||
                String(item.qrcode_status)
                    .trim()
                    .toLowerCase()
                    .includes(searchValue.trim().toLowerCase()) ||
                String(item.account_status)
                    .trim()
                    .toLowerCase()
                    .includes(searchValue.trim().toLowerCase())
        );

        if (showAll) return filteredData;

        return filteredData.slice((page - 1) * limitOnPage, page * limitOnPage);
    }, [checks, searchValue, page, limitOnPage, showAll]);

    const verifyChecks = useMemo(() => {
        return info?.filter((item) => item.qrcode_status === "verified");
    }, [info]);
    const waitChecks = useMemo(() => {
        return info?.filter((item) => item.qrcode_status === "wait");
    }, [info]);
    const notVerifyChecks = useMemo(() => {
        return info?.filter((item) => item.qrcode_status === "not verified");
    }, [info]);

    return (
        <Flex direction='column' pt={{ sm: "125px", md: "75px" }}>
            <SimpleGrid columns={{ sm: "1", md: "4" }} spacing='24px' mb='24px'>
                <StatComponent
                    title={"Общее количество чеков в промоакции"}
                    amount={info.length}
                    icon={<WalletIcon h={"24px"} w={"24px"} />}
                />

                <StatComponent
                    title={"Количество чеков на проверке"}
                    amount={waitChecks.length}
                    icon={<DocumentIcon h={"24px"} w={"24px"} />}
                />

                <StatComponent
                    title={"Количество одобренных чеков"}
                    amount={verifyChecks.length}
                    icon={<GlobeIcon h={"24px"} w={"24px"} />}
                />

                <StatComponent
                    title={"Количество отклоненных чеков"}
                    amount={notVerifyChecks.length}
                    icon={<CartIcon h={"24px"} w={"24px"} />}
                />
            </SimpleGrid>

            <Flex mb='25px'>
                <InputGroup>
                    <InputLeftElement pointerEvents='none' pl='10px' pt='5px'>
                        <SearchIcon color='gray.300' />
                    </InputLeftElement>

                    <Input
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        w='100%'
                        fontSize='sm'
                        ms='4px'
                        borderRadius='15px'
                        type='text'
                        placeholder='Поиск...'
                        mb='4px'
                        size='lg'
                    />
                </InputGroup>
            </Flex>

            <Flex
                direction={{ sm: "column", md: "row" }}
                justify='space-between'
                align='center'
                w='100%'
                mb='24px'
            >
                <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                        {limitOnPage}
                    </MenuButton>

                    <MenuList onClick={allUsers}>
                        <MenuItem>5</MenuItem>
                        <MenuItem>10</MenuItem>
                        <MenuItem>20</MenuItem>
                        <MenuItem>Все</MenuItem>
                    </MenuList>
                </Menu>

                <Button onClick={exportToExcel}>Выгрузить {limitOnPage}</Button>
            </Flex>

            <Card px='0px'>
                <CardBody>
                    <CheckTable data={filteredChecks} open={openCard} />
                </CardBody>
            </Card>

            {!showAll && (
                <Flex mt='22px'>
                    <Stack direction={["column", "row"]} spacing='10px'>
                        <Button
                            leftIcon={<ChevronLeftIcon />}
                            onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
                            isDisabled={page === 1}
                        />
                        <Button>{page}</Button>
                        <Button
                            rightIcon={<ChevronRightIcon />}
                            onClick={() =>
                                setPage((prevPage) => Math.min(prevPage + 1, totalPages))
                            }
                            isDisabled={page === totalPages}
                        />
                    </Stack>
                </Flex>
            )}
        </Flex>
    );
}

export default CheckData;
