import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, Text, Flex } from "@chakra-ui/react";
import axios from "axios";
import ApexCharts from "react-apexcharts";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { membersOptions, checksOptions, checkAnalytics } from "./ChartsOptions";

function Stat() {
    const [membersData, setMembersData] = useState([]);
    const [membersStats, setMembersStats] = useState({
        series: [{ name: "участники", data: [0, 0, 0] }],
    });

    const [checksData, setChecksData] = useState([]);
    const [checkStat, setCheckStat] = useState({
        series: [{ name: "чеки", data: [0, 0, 0] }],
    });

    const [reason, setReason] = useState({ series: [0], options: {} });
    const [avgCheckSum, setAvgCheckSum] = useState({ series: [{ name: "сумма", data: [0] }] });

    useEffect(() => {
        getMembersData();
        getChecksStat();
    }, []);

    async function getMembersData() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/statistics`);
            setMembersData(res.data.data);
        } catch (error) {
            console.error(error.message);
        }
    }

    const activeMembers = useMemo(() => {
        return membersData?.filter((el) => el.status === "active").length;
    }, [membersData]);
    const notActiveMembers = useMemo(() => {
        return membersData?.filter((el) => el.status === "not active").length;
    }, [membersData]);

    useEffect(() => {
        setMembersStats({
            series: [
                { name: "участники", data: [membersData.length, activeMembers, notActiveMembers] },
            ],
        });
    }, [membersData]);

    async function getChecksStat() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/qr/stat`);
            setChecksData(res.data.data);
        } catch (error) {
            console.error(error.message);
        }
    }

    const verifiedChecks = useMemo(() => {
        return checksData?.filter((el) => el.qrcode_status === "verified").length;
    }, [checksData]);
    const notVerifiedChecks = useMemo(() => {
        return checksData?.filter((el) => el.qrcode_status === "not verified").length;
    }, [checksData]);
    const waitChecks = useMemo(() => {
        return checksData?.filter((el) => el.qrcode_status === "wait").length;
    }, [checksData]);

    useEffect(() => {
        setCheckStat({
            series: [
                {
                    name: "чеки",
                    data: [checksData.length, verifiedChecks, notVerifiedChecks, waitChecks],
                },
            ],
        });
    }, [checksData]);

    const filteredChecks = useMemo(() => {
        return checksData.filter((el) => el.summary !== null && el.summary !== "none");
    }, [checksData]);
    const checkAmountSum = useMemo(() => {
        return filteredChecks.reduce((sum, el) => +el.summary + sum, 0);
    }, [filteredChecks]);
    const res = useMemo(() => {
        return filteredChecks.length > 0 ? Math.round(checkAmountSum / filteredChecks.length) : 0;
    }, [checkAmountSum, filteredChecks]);

    useEffect(() => {
        setAvgCheckSum({ series: [{ name: "сумма", data: [res] }] });
    }, [checksData]);

    useEffect(() => {
        const reasons = checksData.reduce((acc, obj) => {
            const reason = obj.reason;

            if (reason !== null) {
                acc[reason] = (acc[reason] || 0) + 1;
            }

            return acc;
        }, {});

        const reasonData = {
            series: Object.values(reasons),
            options: {
                chart: {
                    type: "bar",
                },
                legend: {
                    position: "bottom",
                    horizontalAlign: "center",
                    labels: {
                        colors: "#64748b",
                        useSeriesColors: false,
                    },
                },
                labels: Object.keys(reasons),
            },
        };

        setReason(reasonData);
    }, [checksData]);

    return (
        <>
            <Grid
                templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                gap='24px'
                pt={{ md: "75px" }}
            >
                <Card>
                    <CardHeader>
                        <Text fontSize='lg' fontWeight='bold'>
                            Участники
                        </Text>
                    </CardHeader>

                    <CardBody h='100%'>
                        <Box w='100%' h='100%' minH={{ sm: "200px", lg: "300px" }}>
                            <ApexCharts
                                series={membersStats.series}
                                options={membersOptions.options}
                                type='bar'
                            />
                        </Box>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader>
                        <Text fontSize='lg' fontWeight='bold'>
                            Чеки
                        </Text>
                    </CardHeader>

                    <CardBody h='100%'>
                        <Box w='100%' h='100%' minH={{ sm: "200px", lg: "300px" }}>
                            <ApexCharts
                                series={checkStat.series}
                                options={checksOptions.options}
                                type='bar'
                            />
                        </Box>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader>
                        <Text fontSize='lg' fontWeight='bold'>
                            Аналитика по чекам
                        </Text>
                    </CardHeader>

                    <CardBody h='100%'>
                        <Box w='100%' h='100%' minH={{ sm: "200px", lg: "300px" }}>
                            <ApexCharts
                                series={avgCheckSum.series}
                                options={checkAnalytics.options}
                                type='bar'
                            />
                        </Box>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader>
                        <Text fontSize='lg' fontWeight='bold'>
                            Причины отклонения чеков
                        </Text>
                    </CardHeader>

                    <CardBody h='100%'>
                        <Box w='100%' h='100%' minH={{ sm: "200px", lg: "300px" }}>
                            <ApexCharts
                                series={reason.series}
                                options={reason.options}
                                type='pie'
                            />
                        </Box>
                    </CardBody>
                </Card>
            </Grid>
        </>
    );
}

export default Stat;
