/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { createIcon } from "@chakra-ui/icons";

export const AdobexdLogo = createIcon({
    displayName: "AdobexdLogo",
    viewBox: "0 0 24 24",
    path: (
        <g clip-path='url(#clip0)'>
            <path
                d='M4.042 0h12.916A3.539 3.539 0 0120.5 3.542v12.416a3.539 3.539 0 01-3.542 3.542H4.042A3.539 3.539 0 01.5 15.958V3.542A3.539 3.539 0 014.042 0z'
                fill='#470137'
            />
            <path
                d='M11.017 5.124l-2.5 4.125 2.666 4.375a.143.143 0 01.017.1c-.008.034-.042.009-.092.017H9.2c-.133 0-.225-.008-.283-.092-.175-.35-.359-.691-.534-1.041a33.608 33.608 0 00-.566-1.05c-.2-.359-.4-.717-.6-1.084H7.2c-.175.359-.367.717-.558 1.075-.192.359-.384.717-.567 1.067-.192.35-.383.708-.575 1.05-.033.083-.1.092-.192.092H3.475c-.033 0-.058.016-.058-.025a.116.116 0 01.016-.092l2.592-4.25L3.5 5.116c-.025-.033-.033-.067-.017-.083.017-.025.05-.034.084-.034h1.891c.042 0 .084.009.117.017a.236.236 0 01.083.075c.159.358.342.717.534 1.075.2.358.391.708.6 1.058.2.35.383.7.558 1.059h.017c.175-.367.358-.725.541-1.075.184-.35.375-.7.567-1.05.192-.35.375-.709.558-1.05.009-.034.025-.067.05-.084a.159.159 0 01.109-.016h1.758a.077.077 0 01.091.058c.009.008-.008.042-.024.058zM14.866 13.917a4.144 4.144 0 01-1.791-.375 2.906 2.906 0 01-1.259-1.134c-.308-.508-.458-1.141-.458-1.9a3.423 3.423 0 011.783-3.05c.584-.325 1.284-.483 2.109-.483.041 0 .1 0 .175.008.075.009.158.009.258.017V4.367c0-.059.025-.092.083-.092h1.692c.042-.008.075.025.083.058v7.95c0 .15.009.317.017.5.017.175.025.342.033.484 0 .058-.025.108-.083.133a6.365 6.365 0 01-1.358.4 7.35 7.35 0 01-1.284.117zm.817-1.667V8.583c-.075-.016-.15-.033-.225-.041-.092-.009-.183-.017-.275-.017-.325 0-.65.067-.942.217-.283.141-.525.35-.708.616-.183.267-.275.625-.275 1.059-.008.291.042.583.142.858.083.225.208.425.375.592.158.15.35.266.566.333.225.075.459.108.692.108.125 0 .242-.008.35-.016a.73.73 0 00.3-.042z'
                fill='#FF61F6'
            />
        </g>
    ),
});

export const AtlassianLogo = createIcon({
    displayName: "AtlassianLogo",
    viewBox: "0 0 24 24",
    path: (
        <g>
            <path
                d='M6.427 9.239a.57.57 0 00-.798-.108.591.591 0 00-.167.209l-4.9 9.803a.586.586 0 00.524.847h6.827a.562.562 0 00.523-.324c1.474-3.043.58-7.67-2.01-10.427z'
                fill='#2684FF'
            />
            <path
                d='M10.028.318a12.932 12.932 0 00-.755 12.765l3.292 6.583a.586.586 0 00.523.324h6.826a.583.583 0 00.586-.585c0-.091-.02-.18-.06-.262L11.024.315a.552.552 0 00-.997 0v.003z'
                fill='#2684FF'
            />
        </g>
    ),
});

export const CartIcon = createIcon({
    displayName: "CartIcon",
    viewBox: "0 0 24 24",
    path: (
        <path
            fill='currentColor'
            d='M7.984 19.937a1.406 1.406 0 100-2.812 1.406 1.406 0 000 2.812zM17.828 19.937a1.406 1.406 0 100-2.812 1.406 1.406 0 000 2.812zM20.324 5.558a1.051 1.051 0 00-.815-.386H6.134l-.27-1.528a.703.703 0 00-.692-.581H2.359a.703.703 0 000 1.406h2.223L6.59 15.841a.703.703 0 00.692.581h11.25a.703.703 0 100-1.406H7.871l-.248-1.406h10.62a1.057 1.057 0 001.035-.848l1.266-6.328a1.055 1.055 0 00-.22-.876z'
        />
    ),
});

export const ClockIcon = createIcon({
    displayName: "ClockIcon",
    viewBox: "0 0 24 24",
    path: (
        <g>
            <g>
                <rect fill='none' height='24' width='24' />
            </g>
            <g>
                <g>
                    <g>
                        <path
                            fill='currentColor'
                            d='M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z'
                        />
                    </g>
                </g>
            </g>
        </g>
    ),
});
export const CreativeTimLogo = createIcon({
    displayName: "CreativeTimLogo",
    viewBox: "0 0 100 100",
    path: (
        <g xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' version='1.1'>
            <g
                id='logo-creative-tim-black'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'
            >
                <g
                    id='icon'
                    transform='translate(15.000000, 19.000000)'
                    fill='currentColor'
                    fillRule='nonzero'
                >
                    <path
                        d='M66.4346744,1.49018376e-13 L3.56968951,1.49018376e-13 C1.59820443,1.49018376e-13 -2.98036753e-13,1.59820443 -2.98036753e-13,3.56968951 L-2.98036753e-13,33.5035241 C-2.98036753e-13,35.4750091 1.59820443,37.0732136 3.56968951,37.0732136 L16.4677598,37.0732136 L16.4677598,66.4346744 C16.4666001,67.3810129 16.8417189,68.288989 17.5104719,68.9585615 C18.1792249,69.628134 19.0867408,70.0043639 20.03308,70.0043639 L49.9669146,70.0043639 C51.9383997,70.0043639 53.5366041,68.4061595 53.5366041,66.4346744 L53.5366041,37.0863214 L66.4346744,37.0863214 C67.3810136,37.0863221 68.2885296,36.7100915 68.9572826,36.0405189 C69.6260355,35.3709464 70.0011544,34.4629704 70,33.5166319 L70,3.56968951 C70.0011544,2.62335099 69.6260355,1.71537495 68.9572826,1.04580242 C68.2885296,0.37622988 67.3810136,1.49018376e-13 66.4346744,1.49018376e-13 Z M4.13769396,4.14206323 L32.9311503,4.14206323 L32.9311503,32.9355196 L4.13769396,32.9355196 L4.13769396,4.14206323 Z M49.3989102,65.86667 L20.6054538,65.86667 L20.6054538,37.0863214 L49.3989102,37.0863214 L49.3989102,65.86667 Z M65.8623007,32.9355196 L37.0688443,32.9355196 L37.0688443,4.14206323 L65.8623007,4.14206323 L65.8623007,32.9355196 Z'
                        id='Shape'
                    />
                    <path
                        d='M23.6988934,23.3056595 C24.5142794,24.0708897 25.7898859,24.0506562 26.5805917,23.2599503 C27.3712976,22.4692445 27.3915311,21.193638 26.6263009,20.378252 L20.015603,13.767554 C19.2050095,12.9645312 17.8987889,12.9645312 17.0881954,13.767554 L10.4600204,20.378252 C9.90907005,20.8953125 9.68342943,21.6713831 9.87125954,22.4032422 C10.0590897,23.1351013 10.6305787,23.7065903 11.3624377,23.8944204 C12.0942968,24.0822505 12.8703675,23.8566099 13.387428,23.3056595 L18.5344222,18.1586654 L23.6988934,23.3056595 Z'
                        id='Path'
                    />
                    <path
                        d='M46.3185783,23.3056595 L51.4655725,18.1586654 L56.6125667,23.3056595 C57.4279526,24.0708897 58.7035592,24.0506562 59.494265,23.2599503 C60.2849709,22.4692445 60.3052044,21.193638 59.5399742,20.378252 L52.9292763,13.767554 C52.1186828,12.9645312 50.8124622,12.9645312 50.0018687,13.767554 L43.3911708,20.378252 C42.6259406,21.193638 42.6461741,22.4692445 43.43688,23.2599503 C44.2275858,24.0506562 45.5031924,24.0708897 46.3185783,23.3056595 L46.3185783,23.3056595 Z'
                        id='Path'
                    />
                    <path
                        d='M29.8508185,46.6987044 C29.0342976,45.9346441 27.7586832,45.9567597 26.9691385,46.7486649 C26.1795937,47.54057 26.1612859,48.8162446 26.9277802,49.6304812 L33.5384782,56.2368099 C33.9262423,56.6258256 34.452915,56.8444779 35.002182,56.8444779 C35.5514489,56.8444779 36.0781217,56.6258256 36.4658857,56.2368099 L43.0765837,49.6304812 C43.6002952,49.1075502 43.8052442,48.3449453 43.6142284,47.6299323 C43.4232126,46.9149194 42.8652519,46.3561259 42.1505246,46.1640439 C41.4357974,45.971962 40.6728876,46.1757734 40.1491761,46.6987044 L35.002182,51.8500678 L29.8508185,46.6987044 Z'
                        id='Path'
                    />
                </g>
            </g>
        </g>
    ),

    //   <chakra.svg
    //   height="50px"
    //   width="50px"
    //   viewBox="0 0 100 100"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   color={fill}
    //   {...props}
    // >
    // </chakra.svg>
});
export const CheckngoLogo = createIcon({
    displayName: "CheckngoLogo",
    viewBox: "0 0 191 191",
    path: (
        <rect width='191' height='191' rx='15.28' fill='#DCFA73'></rect>
        // <path d="M166.048 18.9666C167.607 18.9704 169.101 19.5812 170.202 20.6651C171.304 21.7491 171.923 23.2178 171.925 24.7497V39.3907H178.175V24.7497C178.173 21.5889 176.895 18.5579 174.621 16.3222C172.348 14.0864 169.265 12.8286 166.048 12.8247L151.151 12.8247V18.9666H166.048Z" fill="#1A1A1A"></path>
        // <path d="M24.9558 171.925C23.3982 171.923 21.9049 171.304 20.8035 170.202C19.702 169.1 19.0824 167.606 19.0804 166.048V151.151H12.8252V166.048C12.8291 169.265 14.1087 172.348 16.383 174.621C18.6573 176.895 21.7405 178.173 24.9558 178.175H39.8492V171.925H24.9558Z" fill="#1A1A1A"></path>
        //  <path d="M19.0804 24.7497C19.0824 23.2178 19.7018 21.7491 20.803 20.6651C21.9042 19.5812 23.3975 18.9704 24.9558 18.9666H39.8492V12.8247H24.9558C21.7398 12.8286 18.6566 14.0862 16.3825 16.3217C14.1084 18.5573 12.8291 21.5882 12.8252 24.7497L12.8252 39.3907H19.0804V24.7497Z" fill="#1A1A1A"></path>
        //  <path d="M171.925 166.048C171.923 167.606 171.304 169.1 170.202 170.202C169.1 171.304 167.606 171.923 166.048 171.925H151.151V178.175H166.048C169.264 178.173 172.347 176.895 174.621 174.621C176.895 172.347 178.173 169.264 178.175 166.048V151.151H171.925V166.048Z" fill="#1A1A1A"></path> <rect x="82" y="40" width="7" height="7" fill="#1A1A1A"></rect>
        //  <rect x="82" y="55" width="7" height="20" fill="#1A1A1A"></rect> <path fill-rule="evenodd" clip-rule="evenodd" d="M100 140H93V152H82V159H100V154V152V140Z" fill="#1A1A1A"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M40 117H33V124V152V159H40H68H75V152V124V117H68H40ZM68 124H40V152H68V124Z" fill="#1A1A1A"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M124 117H117V124V152V159H124H152H159V152V124V117H152H124ZM152 124H124V152H152V124Z" fill="#1A1A1A"></path>
        //   <path d="M145 47L131 47.0003V61H145V47Z" fill="#1A1A1A"></path> <path d="M145 131L131 131V145H145V131Z" fill="#1A1A1A"></path> <path d="M61 131L47 131V145H61V131Z" fill="#1A1A1A"></path> <path d="M61 47L47 47.0003V61H61V47Z" fill="#1A1A1A"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M124 33H117V40V68V75H124H152H159V68V40V33H152H124ZM152 40H124V68H152V40Z" fill="#1A1A1A"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M40 33H33V40V68V75H40H68H75V68V40V33H68H40ZM68 40H40V68H68V40Z" fill="#1A1A1A"></path>
        //    <rect x="86" y="131" width="7" height="9" fill="#1A1A1A"></rect> <rect x="103" y="40" width="7" height="7" fill="#1A1A1A"></rect> <rect x="103" y="64" width="7" height="11" fill="#1A1A1A"></rect> <rect x="96" y="47" width="7" height="17" fill="#1A1A1A"></rect> <path fill-rule="evenodd" clip-rule="evenodd" d="M107 117H82V124H100V140H107V124V119V117Z" fill="#1A1A1A"></path> <path d="M40.1873 103.375C38.1132 103.375 36.3935 102.692 35.0282 101.327C33.6761 99.9484 33 98.2352 33 96.1873C33 94.1394 33.6761 92.4329 35.0282 91.0676C36.3935 89.6892 38.1132 89 40.1873 89C41.8939 89 43.3904 89.5251 44.6769 90.5753C45.9634 91.6255 46.7314 92.9645 46.9808 94.5923H43.3379C43.1541 93.936 42.7734 93.4174 42.1958 93.0367C41.6182 92.6429 40.9487 92.446 40.1873 92.446C39.0846 92.446 38.1919 92.7939 37.5093 93.4896C36.8398 94.1722 36.5051 95.0715 36.5051 96.1873C36.5051 97.3032 36.8398 98.209 37.5093 98.9047C38.1919 99.5874 39.0846 99.9287 40.1873 99.9287C40.9487 99.9287 41.6182 99.7383 42.1958 99.3576C42.7866 98.9638 43.1673 98.4387 43.3379 97.7823H47.0005C46.7511 99.4101 45.9831 100.749 44.6966 101.799C43.4101 102.85 41.907 103.375 40.1873 103.375Z" fill="#1A1A1A"></path>
        //     <path d="M56.2156 89.2954H59.7206V103.079H56.2156V97.6839H52.7302V103.079H49.2055V89.2954H52.7302V94.297H56.2156V89.2954Z" fill="#1A1A1A"></path> <path d="M66.308 99.7711H71.2505V103.079H62.7832V89.2954H71.152V92.6035H66.308V94.5529H70.6598V97.6839H66.308V99.7711Z" fill="#1A1A1A"></path> <path d="M80.3053 103.375C78.2311 103.375 76.5114 102.692 75.1462 101.327C73.794 99.9484 73.1179 98.2352 73.1179 96.1873C73.1179 94.1394 73.794 92.4329 75.1462 91.0676C76.5114 89.6892 78.2311 89 80.3053 89C82.0118 89 83.5084 89.5251 84.7949 90.5753C86.0814 91.6255 86.8493 92.9645 87.0988 94.5923H83.4559C83.2721 93.936 82.8914 93.4174 82.3138 93.0367C81.7362 92.6429 81.0667 92.446 80.3053 92.446C79.2026 92.446 78.3099 92.7939 77.6273 93.4896C76.9577 94.1722 76.623 95.0715 76.623 96.1873C76.623 97.3032 76.9577 98.209 77.6273 98.9047C78.3099 99.5874 79.2026 99.9287 80.3053 99.9287C81.0667 99.9287 81.7362 99.7383 82.3138 99.3576C82.9045 98.9638 83.2852 98.4387 83.4559 97.7823H87.1185C86.869 99.4101 86.1011 100.749 84.8146 101.799C83.5281 102.85 82.025 103.375 80.3053 103.375Z" fill="#1A1A1A"></path>
        //      <path d="M100.744 103.079H96.8061L92.8482 96.8962V103.079H89.3234V89.2954H92.8482V95.0846L96.6092 89.2954H100.547L96.1563 95.9904L100.744 103.079Z" fill="#1A1A1A"></path> <path d="M116.417 89.2954H119.922V103.079H117.205L112.735 95.951V103.079H109.21V89.2954H111.947L116.417 96.4236V89.2954Z" fill="#1A1A1A"></path> <path d="M135.563 95.4981H142.258V103.079H139.127V101.721C138.182 102.823 136.823 103.375 135.051 103.375C133.095 103.375 131.474 102.685 130.187 101.307C128.901 99.9287 128.257 98.2221 128.257 96.1873C128.257 94.1394 128.933 92.4329 130.286 91.0676C131.651 89.6892 133.371 89 135.445 89C137.112 89 138.576 89.4857 139.836 90.4572C141.109 91.4286 141.903 92.6363 142.219 94.0804H138.536C138.3 93.6078 137.906 93.2205 137.355 92.9186C136.803 92.6035 136.173 92.446 135.464 92.446C134.349 92.446 133.449 92.7939 132.767 93.4896C132.097 94.1722 131.762 95.0715 131.762 96.1873C131.762 97.3819 132.104 98.3402 132.786 99.0623C133.469 99.7711 134.355 100.126 135.445 100.126C136.981 100.126 138.011 99.5611 138.536 98.4321H135.563V95.4981Z" fill="#1A1A1A"></path>
        //       <path d="M156.893 101.307C155.502 102.685 153.795 103.375 151.773 103.375C149.752 103.375 148.039 102.685 146.634 101.307C145.242 99.9155 144.547 98.209 144.547 96.1873C144.547 94.1657 145.242 92.4657 146.634 91.0873C148.039 89.6958 149.752 89 151.773 89C153.795 89 155.502 89.6958 156.893 91.0873C158.298 92.4657 159 94.1657 159 96.1873C159 98.209 158.298 99.9155 156.893 101.307ZM149.115 98.885C149.824 99.5808 150.71 99.9287 151.773 99.9287C152.837 99.9287 153.723 99.5808 154.432 98.885C155.141 98.1761 155.495 97.2769 155.495 96.1873C155.495 95.0977 155.141 94.2051 154.432 93.5093C153.723 92.8004 152.837 92.446 151.773 92.446C150.71 92.446 149.824 92.8004 149.115 93.5093C148.406 94.2051 148.052 95.0977 148.052 96.1873C148.052 97.2769 148.406 98.1761 149.115 98.885Z" fill="#1A1A1A"></path> <path d="M103.826 89.3284H107.764L104.975 93.5948H101.037L103.826 89.3284Z" fill="#1A1A1A"></path> <path d="M125.324 89H129.262L126.472 93.2664H122.534L125.324 89Z" fill="#1A1A1A"></path> </svg>
    ),
});
export const CreditIcon = createIcon({
    displayName: "CreditIcon",
    viewBox: "0 0 24 24",
    path: (
        <path
            fill='currentColor'
            d='M1.406 17.023a2.461 2.461 0 002.461 2.46h14.766a2.461 2.461 0 002.46-2.46v-6.768H1.407v6.768zm2.9-3.34a1.318 1.318 0 011.319-1.318h2.11a1.318 1.318 0 011.318 1.318v.879a1.318 1.318 0 01-1.319 1.318H5.625a1.318 1.318 0 01-1.318-1.318v-.879zM18.633 4.014H3.867a2.46 2.46 0 00-2.46 2.461v1.143h19.687V6.475a2.46 2.46 0 00-2.461-2.46z'
        />
    ),
});

export const DashboardLogo = createIcon({
    displayName: "DashboardLogo",
    viewBox: "0 0 1000 257",
    path: (
        <g width='998' height='257' viewBox='0 0 998 257' fill='none'>
            <g clip-path='url(#clip0)'>
                <path
                    d='M388.5 115.302C406.112 115.302 413.966 126.726 416.584 136.96L441.336 127.916C436.576 109.352 419.44 89.836 388.262 89.836C354.942 89.836 329 115.54 329 151.24C329 186.464 354.942 212.882 388.976 212.882C419.44 212.882 436.814 193.128 442.288 175.04L418.012 166.234C415.394 175.04 407.064 187.654 388.976 187.654C371.602 187.654 356.608 174.564 356.608 151.24C356.608 127.916 371.602 115.302 388.5 115.302Z'
                    fill='currentColor'
                />
                <path
                    d='M484.894 141.244C485.37 126.488 493.7 115.064 508.932 115.064C526.306 115.064 532.732 126.488 532.732 140.768V209.312H560.34V136.008C560.34 110.542 546.536 90.074 517.976 90.074C505.838 90.074 492.748 94.358 484.894 104.592V37H457.286V209.312H484.894V141.244Z'
                    fill='currentColor'
                />
                <path
                    d='M577.29 177.896C577.29 196.222 592.284 212.882 616.56 212.882C635.362 212.882 646.786 203.362 652.26 194.556C652.26 203.838 653.212 208.598 653.45 209.312H679.154C678.916 208.122 677.726 201.22 677.726 190.748V133.152C677.726 110.066 664.16 89.836 628.46 89.836C599.9 89.836 581.812 107.686 579.67 127.678L604.898 133.39C606.088 121.728 614.18 112.446 628.698 112.446C643.93 112.446 650.594 120.3 650.594 130.058C650.594 134.104 648.69 137.436 642.026 138.388L612.276 142.91C592.522 145.766 577.29 157.19 577.29 177.896ZM621.796 191.224C610.848 191.224 604.898 184.084 604.898 176.23C604.898 166.71 611.8 161.95 620.368 160.522L650.594 156V161.236C650.594 183.846 637.266 191.224 621.796 191.224Z'
                    fill='currentColor'
                />
                <path
                    d='M810.108 93.406H773.456L728.95 140.292V37H701.58V209.312H728.95V176.944L743.23 161.95L777.264 209.312H811.06L762.508 142.434L810.108 93.406Z'
                    fill='currentColor'
                />
                <path
                    d='M889.349 92.692C888.159 92.454 885.303 91.978 881.971 91.978C866.739 91.978 853.887 99.356 848.413 111.97V93.406H821.519V209.312H849.127V154.096C849.127 132.438 858.885 120.062 880.305 120.062C883.161 120.062 886.255 120.3 889.349 120.776V92.692Z'
                    fill='currentColor'
                />
                <path
                    d='M895.968 177.896C895.968 196.222 910.962 212.882 935.238 212.882C954.04 212.882 965.464 203.362 970.938 194.556C970.938 203.838 971.89 208.598 972.128 209.312H997.832C997.594 208.122 996.404 201.22 996.404 190.748V133.152C996.404 110.066 982.838 89.836 947.138 89.836C918.578 89.836 900.49 107.686 898.348 127.678L923.576 133.39C924.766 121.728 932.858 112.446 947.376 112.446C962.608 112.446 969.272 120.3 969.272 130.058C969.272 134.104 967.368 137.436 960.704 138.388L930.954 142.91C911.2 145.766 895.968 157.19 895.968 177.896ZM940.474 191.224C929.526 191.224 923.576 184.084 923.576 176.23C923.576 166.71 930.478 161.95 939.046 160.522L969.272 156V161.236C969.272 183.846 955.944 191.224 940.474 191.224Z'
                    fill='currentColor'
                />
                <rect width='257' height='257' rx='128.5' fill='#4FD1C5' />
                <path
                    d='M69.5584 133.985L157.15 46.9959C158.787 45.3708 161.42 47.3484 160.315 49.3729L127.714 109.125C126.987 110.457 127.951 112.083 129.47 112.083H185.809C187.624 112.083 188.501 114.306 187.174 115.545L88.4456 207.687C86.6753 209.339 84.0405 207.011 85.4617 205.051L132.197 140.578C133.156 139.256 132.211 137.404 130.578 137.404H70.9677C69.1826 137.404 68.2917 135.243 69.5584 133.985Z'
                    fill='white'
                />
            </g>
            <defs>
                <linearGradient
                    id='paint0_linear'
                    x1='128.5'
                    y1='0'
                    x2='128.5'
                    y2='257'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#7BCBD4' />
                    <stop offset='1' stop-color='#29C6B7' />
                </linearGradient>
                <clipPath id='clip0'>
                    <rect width='997.832' height='257' fill='white' />
                </clipPath>
            </defs>
        </g>
    ),
});

export const DashboardLogoWhite = createIcon({
    displayName: "DashboardLogo",
    viewBox: "0 0 163.5 42",
    path: (
        <g fill='none'>
            <path
                d='M63.452 19.292c2.877 0 4.16 1.911 4.587 3.623l4.043-1.513c-.777-3.106-3.576-6.371-8.668-6.371-5.443 0-9.68 4.3-9.68 10.274 0 5.893 4.237 10.313 9.796 10.313 4.976 0 7.813-3.305 8.707-6.331l-3.965-1.473c-.427 1.473-1.788 3.583-4.742 3.583-2.837 0-5.286-2.19-5.286-6.092 0-3.903 2.449-6.013 5.208-6.013zM79.196 23.632c.078-2.469 1.438-4.38 3.926-4.38 2.838 0 3.887 1.911 3.887 4.3v11.47h4.51V22.755c0-4.26-2.255-7.685-6.92-7.685-1.982 0-4.12.716-5.403 2.429V6.19h-4.509v28.831h4.51V23.632zM94.287 29.765c0 3.066 2.449 5.853 6.414 5.853 3.071 0 4.936-1.592 5.83-3.066 0 1.553.156 2.35.195 2.47h4.198c-.039-.2-.233-1.355-.233-3.107v-9.637c0-3.862-2.216-7.247-8.047-7.247-4.664 0-7.619 2.986-7.968 6.332l4.12.955c.194-1.951 1.516-3.504 3.887-3.504 2.488 0 3.576 1.314 3.576 2.947 0 .677-.311 1.234-1.399 1.393l-4.859.757c-3.226.478-5.714 2.39-5.714 5.854zm7.269 2.23c-1.788 0-2.76-1.195-2.76-2.509 0-1.593 1.127-2.39 2.527-2.628l4.936-.757v.876c0 3.783-2.176 5.018-4.703 5.018zM132.312 15.628h-5.986l-7.269 7.845V6.191h-4.47v28.83h4.47v-5.416l2.332-2.508 5.559 7.924h5.52l-7.93-11.19 7.774-8.203zM145.254 15.509a6.3 6.3 0 00-1.205-.12c-2.487 0-4.587 1.235-5.481 3.345v-3.106h-4.392v19.393h4.509v-9.238c0-3.624 1.594-5.695 5.092-5.695.467 0 .972.04 1.477.12v-4.7zM146.335 29.765c0 3.066 2.449 5.853 6.414 5.853 3.071 0 4.937-1.592 5.831-3.066 0 1.553.156 2.35.194 2.47h4.199c-.039-.2-.234-1.355-.234-3.107v-9.637c0-3.862-2.215-7.247-8.046-7.247-4.665 0-7.619 2.986-7.969 6.332l4.121.955c.194-1.951 1.516-3.504 3.887-3.504 2.488 0 3.576 1.314 3.576 2.947 0 .677-.311 1.234-1.399 1.393l-4.859.757c-3.227.478-5.715 2.39-5.715 5.854zm7.269 2.23c-1.788 0-2.759-1.195-2.759-2.509 0-1.593 1.127-2.39 2.526-2.628l4.937-.757v.876c0 3.783-2.177 5.018-4.704 5.018zM41.975 21.5C41.975 9.626 32.578 0 20.987 0 9.398 0 0 9.626 0 21.5S9.396 43 20.988 43c11.59 0 20.987-9.626 20.987-21.5z'
                fill='#fff'
            />
            <path
                d='M11.36 22.418L25.668 7.863c.267-.272.697.06.517.398l-5.325 9.997c-.119.223.039.495.287.495h9.202c.296 0 .44.372.223.58L14.446 34.748c-.29.277-.72-.113-.488-.44l7.633-10.788c.157-.221.003-.531-.264-.531H11.59c-.292 0-.437-.362-.23-.572z'
                fill='#3BCBBE'
            />
        </g>
    ),
});

export const DocumentIcon = createIcon({
    displayName: "DocumentIcon",
    viewBox: "0 0 24 24",
    path: (
        <g>
            <path
                fill='currentColor'
                d='M18.809 10.344h-6.153a2.11 2.11 0 01-2.11-2.11V2.083a.176.176 0 00-.175-.176H6.328A2.812 2.812 0 003.516 4.72v14.063a2.812 2.812 0 002.812 2.812h9.844a2.812 2.812 0 002.812-2.813V10.52a.176.176 0 00-.175-.176z'
            />
            <path
                fill='currentColor'
                d='M18.423 8.789l-6.32-6.32a.088.088 0 00-.15.062v5.705a.703.703 0 00.703.703h5.705a.088.088 0 00.062-.15z'
            />
        </g>
    ),
});

export const GlobeIcon = createIcon({
    displayName: "GlobeIcon",
    viewBox: "0 0 24 24",
    path: (
        <g>
            <path
                stroke='currentColor'
                fill='transparent'
                d='M11.25 2.109a9.14 9.14 0 100 18.281 9.14 9.14 0 000-18.281z'
                strokeWidth='.75'
                strokeMiterlimit='10'
            />
            <path
                stroke='currentColor'
                fill='transparent'
                d='M11.25 2.109C8.698 2.109 6.3 6.2 6.3 11.249c0 5.048 2.4 9.14 4.951 9.14 2.552 0 4.951-4.092 4.951-9.14 0-5.048-2.399-9.14-4.95-9.14z'
                strokeWidth='.75'
                strokeMiterlimit='10'
            />
            <path
                stroke='currentColor'
                fill='transparent'
                d='M5.156 5.156C6.836 6.349 8.952 7.06 11.25 7.06c2.298 0 4.414-.711 6.094-1.904'
            />
            <path
                stroke='currentColor'
                fill='transparent'
                d='M5.156 5.156C6.836 6.349 8.952 7.06 11.25 7.06c2.298 0 4.414-.711 6.094-1.904M17.344 17.344c-1.68-1.193-3.796-1.904-6.094-1.904-2.298 0-4.413.711-6.094 1.904'
                strokeWidth='.75'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                stroke='currentColor'
                fill='transparent'
                d='M11.25 2.109v18.28M20.39 11.249H2.11'
                strokeWidth='.75'
                strokeMiterlimit='10'
            />
        </g>
    ),
});

export const HelpIcon = createIcon({
    displayName: "HelpIcon",
    viewBox: "0 0 24 24",
    path: (
        <path
            fill='currentColor'
            d='M11.25 0C5.04 0 0 5.04 0 11.25S5.04 22.5 11.25 22.5 22.5 17.46 22.5 11.25 17.46 0 11.25 0zm-.352 17.813a1.172 1.172 0 110-2.344 1.172 1.172 0 010 2.344zm1.96-5.977c-.95.637-1.08 1.222-1.08 1.758a.82.82 0 11-1.641 0c0-1.284.59-2.305 1.806-3.121 1.13-.758 1.768-1.239 1.768-2.295 0-.718-.41-1.264-1.26-1.668-.199-.095-.644-.187-1.191-.18-.687.008-1.22.172-1.63.503-.775.623-.84 1.302-.84 1.312a.821.821 0 11-1.642-.08c.007-.142.106-1.425 1.452-2.507.698-.562 1.585-.854 2.636-.866.745-.01 1.444.117 1.918.34 1.418.672 2.198 1.79 2.198 3.146 0 1.982-1.325 2.872-2.494 3.658z'
        />
    ),
});

export const HomeIcon = createIcon({
    displayName: "HomeIcon",
    viewBox: "0 0 24 24",
    path: (
        <g>
            <path
                fill='currentColor'
                d='M11.494 4.951a.351.351 0 00-.486 0l-8.09 7.729a.352.352 0 00-.109.254v7.254a1.406 1.406 0 001.405 1.406h4.223a.703.703 0 00.704-.703v-5.976a.351.351 0 01.351-.352h3.516a.351.351 0 01.351.352v5.976a.703.703 0 00.704.703h4.22a1.407 1.407 0 001.407-1.406v-7.254a.35.35 0 00-.108-.254L11.494 4.95z'
            />
            <path
                fill='currentColor'
                d='M21.574 11.23l-3.287-3.144V3.314a.703.703 0 00-.703-.703h-2.11a.703.703 0 00-.703.703V4.72l-2.545-2.434c-.239-.24-.593-.378-.976-.378-.38 0-.734.138-.972.379L.93 11.23a.717.717 0 00-.058.983.703.703 0 001.018.046l9.119-8.713a.352.352 0 01.486 0l9.12 8.713a.703.703 0 00.992-.019c.27-.28.248-.74-.033-1.01z'
            />
        </g>
    ),
});

export const InvisionLogo = createIcon({
    displayName: "InvisionLogo",
    viewBox: "0 0 24 24",
    path: (
        <g clip-path='url(#clip0)'>
            <path
                d='M18.687 0H2.313A1.813 1.813 0 00.5 1.811v16.374C.5 19.188 1.312 20 2.313 20h16.374a1.813 1.813 0 001.813-1.813V1.812A1.813 1.813 0 0018.687 0z'
                fill='#DC395F'
            />
            <path
                d='M7.184 6.293c.665 0 1.222-.522 1.222-1.204 0-.683-.557-1.204-1.222-1.204-.665 0-1.222.521-1.222 1.204 0 .682.557 1.204 1.222 1.204zM4.65 12.739a4.134 4.134 0 00-.108.905c0 1.06.575 1.764 1.797 1.764 1.013 0 1.834-.602 2.426-1.573l-.361 1.449h2.012l1.15-4.612c.287-1.168.844-1.774 1.689-1.774.665 0 1.078.413 1.078 1.096 0 .197-.018.413-.09.646l-.593 2.12c-.09.306-.126.611-.126.899 0 1.006.593 1.742 1.833 1.742 1.06 0 1.904-.682 2.371-2.317l-.79-.305c-.395 1.095-.737 1.293-1.006 1.293-.27 0-.414-.18-.414-.538 0-.162.037-.342.09-.558l.575-2.065c.144-.485.198-.915.198-1.31 0-1.546-.934-2.352-2.066-2.352-1.06 0-2.138.956-2.677 1.962l.395-1.806H8.962L8.53 8.996h1.438l-.885 3.544c-.695 1.545-1.972 1.57-2.132 1.534-.264-.06-.432-.159-.432-.5 0-.198.036-.482.126-.823l1.348-5.346H4.579l-.431 1.591h1.419L4.65 12.74'
                fill='#fff'
            />
        </g>
    ),
});

export const JiraLogo = createIcon({
    displayName: "JiraLogo",
    viewBox: "0 0 24 24",
    path: (
        <g clip-path='url(#clip0)'>
            <path
                d='M20.26 10.42l-8.863-8.93-.86-.865-6.67 6.722L.814 10.42a.827.827 0 000 1.162l6.096 6.14 3.627 3.654 6.67-6.722.105-.104 2.947-2.964a.824.824 0 000-1.166zm-9.722 3.649L7.493 11l3.045-3.068L13.582 11l-3.044 3.068z'
                fill='#2684FF'
            />
            <path
                d='M10.537 7.932a5.184 5.184 0 01-1.502-3.637A5.185 5.185 0 0110.515.65L3.852 7.36l3.626 3.654 3.06-3.082z'
                fill='url(#paint0_linear)'
            />
            <path
                d='M13.59 10.992l-3.053 3.076a5.186 5.186 0 011.502 3.653c0 1.37-.54 2.683-1.502 3.652l6.682-6.728-3.63-3.653z'
                fill='url(#paint1_linear)'
            />
        </g>
    ),
});

export const MastercardIcon = createIcon({
    displayName: "MastercardIcon",
    viewBox: "0 0 24 24",
    path: (
        <svg
            width='24'
            height='20'
            viewBox='0 0 21 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle cx='6.63158' cy='7.49999' r='6.63158' fill='#EB001B' />
            <circle cx='14.3686' cy='7.49999' r='6.63158' fill='#F79E1B' />
        </svg>
    ),
});

export const PayPalIcon = createIcon({
    displayName: "PayPalIcon",
    viewBox: "0 0 24 24",
    path: (
        <g>
            <path
                fill='currentColor'
                d='M16.179 2.532C15.142 1.357 13.265.854 10.863.854H3.895a.997.997 0 00-.986.834L.007 19.945a.595.595 0 00.591.686H4.9l1.08-6.8-.033.215a.993.993 0 01.983-.834h2.044c4.017 0 7.16-1.619 8.08-6.3.027-.14.07-.406.07-.406.262-1.732-.001-2.907-.945-3.974z'
            />
            <path
                fill='currentColor'
                d='M18.213 7.393c-.998 4.61-4.184 7.048-9.24 7.048H7.142L5.773 23.1h2.973a.87.87 0 00.862-.731l.035-.184.684-4.297.044-.237a.87.87 0 01.86-.731h.544c3.514 0 6.264-1.416 7.068-5.51.322-1.644.166-3.021-.63-4.017z'
            />
        </g>
    ),
});

export const PersonIcon = createIcon({
    displayName: "PersonIcon",
    viewBox: "0 0 24 24",
    path: (
        <path
            fill='currentColor'
            d='M14.618 3.338c-.855-.924-2.05-1.432-3.368-1.432-1.325 0-2.524.505-3.375 1.423-.86.928-1.28 2.188-1.181 3.55.195 2.686 2.239 4.87 4.556 4.87s4.358-2.184 4.556-4.87c.1-1.349-.322-2.607-1.188-3.541zM18.984 21.592H3.515a1.363 1.363 0 01-1.063-.489 1.552 1.552 0 01-.316-1.279c.371-2.058 1.529-3.786 3.348-5 1.616-1.076 3.664-1.67 5.766-1.67s4.15.594 5.765 1.67c1.82 1.214 2.977 2.942 3.348 5 .085.471-.03.937-.315 1.279a1.362 1.362 0 01-1.064.49z'
        />
    ),
});

export const ProfileIcon = createIcon({
    displayName: "ProfileIcon",
    viewBox: "0 0 24 24",
    path: (
        <g>
            <path d='M0 0h24v24H0V0z' fill='transparent' />
            <path
                fill='currentColor'
                d='M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z'
            />
        </g>
    ),
});

export const RocketIcon = createIcon({
    displayName: "RocketIcon",
    viewBox: "0 0 24 24",
    path: (
        <g>
            <path
                fill='currentColor'
                d='M20.99 2.182a.209.209 0 00-.156-.16c-2.574-.63-8.521 1.613-11.743 4.833a13.93 13.93 0 00-1.566 1.85c-.994-.087-1.987-.014-2.834.355-2.39 1.052-3.085 3.796-3.279 4.976a.424.424 0 00.464.492l3.837-.423c.002.29.02.578.052.866.02.2.108.386.25.527l1.486 1.482a.86.86 0 00.528.25c.286.033.573.05.86.053l-.42 3.832a.424.424 0 00.492.464c1.178-.19 3.927-.885 4.972-3.274.37-.847.445-1.836.36-2.824a13.96 13.96 0 001.855-1.567c3.232-3.216 5.462-9.03 4.842-11.732zm-8.067 7.896a2.11 2.11 0 112.983-2.984 2.11 2.11 0 01-2.983 2.984z'
            />
            <path
                fill='currentColor'
                d='M7.4 18.054c-.24.241-.627.335-1.092.416-1.044.178-1.967-.725-1.779-1.78.072-.401.283-.962.415-1.094a.192.192 0 00-.16-.328 2.636 2.636 0 00-1.544.753c-1.033 1.034-1.13 4.87-1.13 4.87s3.838-.097 4.872-1.13c.417-.417.682-.961.752-1.546.017-.184-.207-.293-.334-.16z'
            />
        </g>
    ),
});

export const SettingsIcon = createIcon({
    displayName: "SettingsIcon",
    viewBox: "0 0 24 24",
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <g>
            <path d='M0,0h24v24H0V0z' fill='none' />
            <path
                fill='currentColor'
                d='M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z'
            />
        </g>
    ),
});

export const SlackLogo = createIcon({
    displayName: "SlackLogo",
    viewBox: "0 0 24 24",
    path: (
        <g clip-path='url(#clip0)' fillRule='evenodd' clip-rule='evenodd'>
            <path
                d='M7.832.5c-1.105 0-2 .919-1.999 2.05 0 1.13.895 2.048 2 2.05h2V2.55c.001-1.13-.894-2.048-2-2.05zm0 5.467H2.5c-1.106 0-2.001.918-2 2.05-.002 1.13.894 2.048 2 2.05h5.332c1.106-.001 2.002-.919 2-2.05.002-1.132-.894-2.05-2-2.05z'
                fill='#36C5F0'
            />
            <path
                d='M20.5 8.016c0-1.13-.895-2.048-2-2.05-1.106.002-2.002.92-2 2.05v2.05h2c1.105 0 2-.918 2-2.05zm-5.334 0V2.55c.001-1.13-.893-2.048-2-2.05-1.105 0-2 .919-2 2.05v5.466c-.001 1.131.894 2.049 2 2.05 1.106 0 2.001-.918 2-2.05z'
                fill='#2EB67D'
            />
            <path
                d='M13.166 21c1.106 0 2.001-.919 2-2.05.001-1.13-.894-2.048-2-2.05h-2v2.05c-.001 1.13.894 2.048 2 2.05zm0-5.467h5.333c1.106-.001 2.002-.92 2-2.05.002-1.131-.893-2.049-1.999-2.05h-5.333c-1.106 0-2.001.918-2 2.05-.001 1.13.893 2.049 1.999 2.05z'
                fill='#ECB22E'
            />
            <path
                d='M.5 13.483c-.001 1.13.895 2.049 2 2.05 1.106-.001 2.001-.92 2-2.05v-2.05h-2c-1.105.001-2.001.919-2 2.05zm5.333 0v5.467c-.001 1.13.894 2.048 2 2.05 1.105 0 2-.919 2-2.05v-5.465c.002-1.131-.894-2.05-2-2.05-1.106 0-2 .917-2 2.048z'
                fill='#E01E5A'
            />
        </g>
    ),
});

export const SpotifyLogo = createIcon({
    displayName: "SpotifyLogo",
    viewBox: "0 0 24 24",
    path: (
        <g clip-path='url(#clip0)'>
            <path
                d='M10.5 0C4.977 0 .5 4.477.5 10s4.477 10 10 10 10-4.477 10-10c0-5.522-4.477-10-10-10zm4.586 14.422a.623.623 0 01-.857.208c-2.348-1.435-5.304-1.76-8.785-.964a.623.623 0 11-.277-1.216c3.808-.87 7.076-.495 9.712 1.115.294.181.387.564.207.857zm1.223-2.722a.78.78 0 01-1.072.257c-2.687-1.652-6.786-2.13-9.965-1.166a.78.78 0 01-.973-.519.781.781 0 01.52-.972c3.632-1.102 8.147-.569 11.233 1.329a.78.78 0 01.258 1.072zm.106-2.835C13.19 6.95 7.875 6.775 4.797 7.708a.935.935 0 11-.543-1.79c3.533-1.072 9.404-.865 13.115 1.338a.935.935 0 01-.954 1.609z'
                fill='#2EBD59'
            />
        </g>
    ),
});

export const SupportIcon = createIcon({
    // Doesn't display the full icon without w and h being specified
    displayName: "BuildIcon",
    viewBox: "0 0 24 24",
    path: (
        <path
            fill='currentColor'
            d='M20.885 5.547a.703.703 0 00-1.122-.176l-2.7 2.702a.708.708 0 01-.995 0l-1.167-1.169a.702.702 0 010-.994l2.689-2.69a.704.704 0 00-.21-1.138c-2.031-.908-4.566-.435-6.164 1.152-1.358 1.348-1.763 3.455-1.11 5.78a.698.698 0 01-.197.703L2.593 16.4a2.82 2.82 0 103.981 3.983l6.754-7.332a.699.699 0 01.693-.2 7.885 7.885 0 002.03.279c1.469 0 2.757-.475 3.686-1.39 1.72-1.695 1.983-4.57 1.148-6.192zM4.623 19.901a1.407 1.407 0 11-.305-2.797 1.407 1.407 0 01.305 2.797z'
        />
    ),
});

export const StatsIcon = createIcon({
    displayName: "StatsIcon",
    viewBox: "0 0 24 24",
    path: (
        <path
            fill='currentColor'
            d='M4.57 22.297H3.164a1.055 1.055 0 01-1.055-1.054v-6.328a1.055 1.055 0 011.055-1.055H4.57a1.055 1.055 0 011.055 1.055v6.328a1.055 1.055 0 01-1.055 1.054zM14.414 22.296h-1.406a1.055 1.055 0 01-1.055-1.055V10.695a1.055 1.055 0 011.055-1.055h1.406a1.055 1.055 0 011.055 1.055V21.24a1.055 1.055 0 01-1.055 1.055zM19.336 22.297H17.93a1.055 1.055 0 01-1.055-1.055V5.773A1.055 1.055 0 0117.93 4.72h1.406a1.055 1.055 0 011.055 1.054v15.47a1.055 1.055 0 01-1.055 1.054zM9.492 22.297H8.086a1.055 1.055 0 01-1.055-1.055V2.257a1.055 1.055 0 011.055-1.054h1.406a1.055 1.055 0 011.055 1.054v18.985a1.055 1.055 0 01-1.055 1.055z'
        />
    ),
});

export const WalletIcon = createIcon({
    displayName: "WalletIcon",
    viewBox: "0 0 24 24",
    path: (
        <g>
            <path
                fill='currentColor'
                d='M4.447 4.818h14.062c.164 0 .328.01.491.031a2.9 2.9 0 00-3.406-2.441L4.03 4.382h-.013a2.9 2.9 0 00-1.805 1.149 3.848 3.848 0 012.236-.713zM18.51 5.875H4.446a2.816 2.816 0 00-2.813 2.812v8.438a2.816 2.816 0 002.813 2.812h14.062a2.815 2.815 0 002.813-2.812V8.687a2.815 2.815 0 00-2.813-2.812zm-2.088 8.437a1.406 1.406 0 110-2.811 1.406 1.406 0 010 2.811z'
            />
            <path
                fill='currentColor'
                d='M1.656 11.651V7.28c0-.952.528-2.549 2.358-2.895 1.553-.291 3.091-.291 3.091-.291s1.011.703.176.703-.813 1.077 0 1.077 0 1.032 0 1.032L4.007 10.62l-2.35 1.032z'
            />
        </g>
    ),
});

export const VisaIcon = createIcon({
    displayName: "VisaIcon",
    viewBox: "0 0 24 24",
    path: (
        <svg
            width='26'
            height='20'
            viewBox='0 0 30 9'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clip-rule='evenodd'
                d='M12.6708 2.96349C12.6568 4.15055 13.653 4.81298 14.4034 5.20684C15.1744 5.61099 15.4333 5.87013 15.4304 6.23147C15.4245 6.78459 14.8153 7.02862 14.2452 7.03812C13.2505 7.05475 12.6723 6.74889 12.2125 6.51753L11.8542 8.32341C12.3155 8.55247 13.1696 8.75217 14.0554 8.76087C16.1345 8.76087 17.4947 7.65543 17.5021 5.94145C17.5102 3.76625 14.7087 3.64579 14.7278 2.67348C14.7344 2.37871 14.9956 2.06408 15.568 1.98406C15.8512 1.94368 16.6332 1.91274 17.5198 2.35252L17.8677 0.605286C17.391 0.418253 16.7782 0.239136 16.0153 0.239136C14.0583 0.239136 12.6819 1.35962 12.6708 2.96349ZM21.2115 0.389687C20.8319 0.389687 20.5118 0.628245 20.3691 0.99433L17.3991 8.63249H19.4767L19.8901 7.40183H22.429L22.6689 8.63249H24.5L22.9021 0.389687H21.2115ZM21.5021 2.61641L22.1016 5.7116H20.4596L21.5021 2.61641ZM10.1518 0.389687L8.51418 8.63249H10.4939L12.1308 0.389687H10.1518ZM7.22303 0.389687L5.16233 6.00003L4.32878 1.22966C4.23097 0.697187 3.84472 0.389687 3.41579 0.389687H0.0471011L0 0.629037C0.691574 0.790671 1.47729 1.0514 1.95326 1.33033C2.24457 1.50067 2.32775 1.64964 2.42336 2.05458L4.00214 8.63249H6.0945L9.3021 0.389687H7.22303Z'
                fill='#4318FF'
            />
        </svg>
    ),
});
