export const membersOptions = {
    options: {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            theme: "dark",
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            },
        },
        dataLabels: {
            enabled: true,
        },
        xaxis: {
            categories: ["Все участники", "Активные", "Неактивные"],
            labels: {
                style: {
                    colors: "#64748b",
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#64748b",
                },
            },
        },
    },
};

export const checksOptions = {
    options: {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            theme: "dark",
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            },
        },
        dataLabels: {
            enabled: true,
        },
        xaxis: {
            categories: [
                "Все чеки",
                "Подтвержденные чеки",
                "Отклоненные чеки",
                "Чеки на модерации",
            ],
            labels: {
                style: {
                    colors: "#64748b",
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#64748b",
                },
            },
        },
    },
};

export const checkAnalytics = {
    options: {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            theme: "dark",
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            },
        },
        dataLabels: {
            enabled: true,
        },
        xaxis: {
            categories: ["Средняя сумма чека"],
            labels: {
                style: {
                    colors: "#64748b",
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#64748b",
                },
            },
        },
    },
};
