import React from "react";
import { Flex, Icon, Td, Text, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { BsCircleFill } from "react-icons/bs";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

const CheckTable = ({ data, open }) => {
    return (
        <Card overflowX='scroll'>
            <CardBody>
                <Table variant='striped' size='sm' colorScheme='gray'>
                    <Thead flex='column' justifyContent='space-between'>
                        <Tr color='gray.500'>
                            <Th color='gray.500'>id чека</Th>
                            <Th color='gray.500'>Дата и время загрузки</Th>
                            <Th color='gray.500'>Email загрузившего</Th>
                            <Th color='gray.500'>ФИО</Th>
                            <Th color='gray.500'>Номер телефона</Th>
                            <Th color='gray.500'>Сумма чека</Th>
                            <Th color='gray.500'>Статус валидации</Th>
                            <Th color='gray.500'>Статус пользователя</Th>
                            <Th color='gray.500'>Контрольная переменная</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((row) => {
                            return (
                                <Tr key={row.id}>
                                    <Td>
                                        <Flex align='center'>
                                            <Text
                                                role='button'
                                                onClick={() => open(row.id)}
                                                fontSize='md'
                                                fontWeight='bold'
                                            >
                                                {row.id || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text fontSize='md' fontWeight='bold'>
                                                {row.create_date || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text color='gray.400' fontSize='md'>
                                                {row.email || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text color='gray.400' fontSize='md'>
                                                {row.fio || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text color='gray.400' fontSize='md'>
                                                {row.phone || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text fontSize='md' fontWeight='bold'>
                                                {row.summary || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex w='max-content' align='center'>
                                            <Icon
                                                as={BsCircleFill}
                                                w='8px'
                                                h='8px'
                                                color={
                                                    row.qrcode_status === "verified"
                                                        ? "teal.300"
                                                        : row.qrcode_status === "not verified"
                                                        ? "red.500"
                                                        : "grey.400"
                                                }
                                                me='6px'
                                            />
                                            <Text color='gray.400' fontSize='md'>
                                                {row.qrcode_status || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Text color='gray.400' fontSize='md'>
                                            {row.account_status || "-"}
                                        </Text>
                                    </Td>

                                    <Td>
                                        <Text color='gray.400' fontSize='md'>
                                            {"-"}
                                        </Text>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default CheckTable;
