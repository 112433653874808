import React, { useState, useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import Check from "./CheckComponent";
import axios from "axios";

function CheckCard() {
    const { id } = useParams();
    const [check, setCheck] = useState({});

    async function getChecks() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/qr/${id}`);
            setCheck(res.data.data);
        } catch (error) {
            console.error(error.message);
        }
    }

    useEffect(() => {
        getChecks();
    }, [id]);

    return (
        <Flex direction='column' pt={{ sm: "125px", md: "75px" }}>
            <Flex
                direction={{ sm: "column", md: "row" }}
                justify='space-between'
                align='center'
                w='100%'
                mb='24px'
            >
                <Text fontSize='4xl' color='grey' fontWeight='bold'>
                    Чек
                </Text>
            </Flex>
            <Flex>
                <Check data={check} />
            </Flex>
        </Flex>
    );
}

export default CheckCard;
