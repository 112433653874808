import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
    Flex,
    Button,
    SimpleGrid,
    Heading,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import WinnersTable from "./components/WinnersTable";

function WinnersBlock() {
    const router = useHistory();
    const [winnersData, setWinnersData] = useState([]);
    const [allWinners, setAllWinners] = useState([]);
    const [period, setPeriod] = useState([]);
    const [week, setWeek] = useState("За все время");

    function openUserCard(id) {
        router.push(`/dashboard/user-card/${id}`);
    }
    function openQrCard(id) {
        router.push(`/dashboard/check-card/${id}`);
    }

    async function exportToExcel() {
        const fileName = "Winners";
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(allWinners);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    function getPeriodWinners(e) {
        const target = e.target.textContent;
        setWeek(target);

        if (target !== "За все время") {
            const filterWinnersData = allWinners.filter((el) => el.raffle_type === target);
            setWinnersData(filterWinnersData);
        } else setWinnersData(allWinners);
    }

    async function getPeriod() {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/toms-raffle/get-all-winners`,
                { hide: false }
            );

            const allRaffleType = response.data.data.map((el) => el.raffle_type);
            const uniqueRaffleTypes = [...new Set(allRaffleType)];

            setPeriod(uniqueRaffleTypes);
        } catch (error) {
            console.error(error.message);
        }
    }

    async function getAllWinners() {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/toms-raffle/get-all-winners`,
                { hide: false }
            );

            setWinnersData(response.data.data);
            setAllWinners(response.data.data);
        } catch (error) {
            console.error(error.message);
        }
    }

    useEffect(() => {
        getPeriod();
        getAllWinners();
    }, []);

    return (
        <Flex direction='column' pt={{ sm: "125px", md: "75px" }}>
            <SimpleGrid columns={{ sm: "1", md: "3" }} gap='20px' mb='24px'>
                <Card>
                    <CardHeader>
                        <Heading>Выбор периода</Heading>
                    </CardHeader>

                    <CardBody mt='20px'>
                        <Menu>
                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                {week}
                            </MenuButton>

                            <MenuList onClick={getPeriodWinners}>
                                <MenuItem>За все время</MenuItem>
                                {period.map((el, index) => (
                                    <MenuItem key={index}>{el}</MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                    </CardBody>
                </Card>
            </SimpleGrid>

            <Button w='max-content' mb='20px' onClick={exportToExcel}>
                Выгрузить список победителей
            </Button>

            <WinnersTable data={winnersData} openUserCard={openUserCard} openQrCard={openQrCard} />
        </Flex>
    );
}

export default WinnersBlock;
