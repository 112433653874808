import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import {
    Stack,
    Text,
    useColorModeValue,
    Button,
    Flex,
    Image,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Link,
    useToast,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

const CheckComponent = ({ data }) => {
    const toast = useToast();
    const textColor = useColorModeValue("gray.700", "white");
    const router = useHistory();
    const [show, setShow] = useState(false);
    const [value, setValue] = useState("Выберете причину отклонения чека");
    const [img, setImg] = useState("");
    const [fnsResponse, setFnsResponse] = useState([]);
    const [duplicate, setDuplicate] = useState({ findDuplicate: false, duplicateChecksData: [] });

    function getImg() {
        const imgType = String(data.image).split(".");
        const checkImg = `${process.env.REACT_APP_API_URL}/qrcodes/${data.image}.${imgType[1]}`;

        setImg(checkImg);
        setShow(!show);
    }

    function getFnsResponse() {
        data.fns_response
            ? setFnsResponse(JSON.parse(data.fns_response).content.items)
            : setFnsResponse([{ name: "none" }]);
    }

    async function duplicateCheck() {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/qr/find-duplicate`,
                { fd: data.fd, fn: data.fn, fp: data.fp }
            );

            if (response.data.data.length >= 2)
                setDuplicate({
                    ...duplicate,
                    findDuplicate: true,
                    duplicateChecksData: response.data.data,
                });
        } catch (error) {
            console.error(error.message);
        }
    }

    async function verifyCheck() {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/qr/change-qrcode-status`, {
                status: "verified",
                reason: "",
                qrcodeId: data.id,
            });

            toast({
                title: `Чек принят`,
                status: "success",
                isClosable: true,
                duration: 3000,
                position: "top",
            });

            router.goBack();
        } catch (error) {
            toast({
                title: error.message,
                status: "error",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        }
    }
    async function notVerifyCheck() {
        try {
            if (!value) throw new Error("Выберите причину отклонения чека");
            if (value === "Выберете причину отклонения чека")
                throw new Error("Выберите причину отклонения чека");

            await axios.post(`${process.env.REACT_APP_API_URL}/qr/change-qrcode-status`, {
                status: "not verified",
                reason: value,
                qrcodeId: data.id,
            });

            toast({
                title: `Чек отклонен`,
                status: "error",
                isClosable: true,
                duration: 3000,
                position: "top",
            });

            router.goBack();
        } catch (error) {
            console.error(error.message);
            toast({
                title: error.message,
                status: "info",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        }
    }

    useEffect(() => {
        getFnsResponse();
        duplicateCheck();
    }, [data]);

    return (
        <>
            <Flex direction='column' w='100%' gap='10'>
                <Button w='max-content' onClick={() => router.goBack()}>
                    Назад
                </Button>

                <Card width='100%'>
                    <CardHeader mb='32px'>
                        <Flex direction='column' gap='10px'>
                            <Text fontSize='lg' color={textColor} fontWeight='bold'>
                                Чек № {data.id}
                            </Text>
                            {duplicate.findDuplicate && (
                                <Text fontSize='lg' fontWeight='bold' textTransform='uppercase'>
                                    Обнаружено совпадением с другим чеком!
                                </Text>
                            )}
                            {duplicate.duplicateChecksData.map((el) => (
                                <Text
                                    key={el.id}
                                    onClick={() => router.push(`/dashboard/check-card/${el.id}`)}
                                >
                                    ЧЕК: <Button colorScheme='red'>{el.id}</Button>
                                </Text>
                            ))}
                        </Flex>
                    </CardHeader>

                    <CardBody>
                        <Stack direction='column' spacing='10px' w='100%'>
                            <Text fontWeight='bold'>Дата загрузки:</Text>
                            <Text>{data.create_date || "none"}</Text>

                            <Text fontWeight='bold'>Дата покупки:</Text>
                            <Text>{data.time || "none"}</Text>

                            <Text fontWeight='bold'>Сумма:</Text>
                            <Text>{data.summary || "none"}</Text>

                            <Text fontWeight='bold'>ФД - номер:</Text>
                            <Text>{data.fd || "none"}</Text>

                            <Text fontWeight='bold'>ФН - номер:</Text>
                            <Text>{data.fn || "none"}</Text>

                            <Text fontWeight='bold'>ФП - номер:</Text>
                            <Text>{data.fp || "none"}</Text>

                            <Text fontWeight='bold'>Дата покупки:</Text>
                            <Text>{data.time || "none"}</Text>

                            <Text fontWeight='bold'>Ответ от ФНС:</Text>
                            {fnsResponse.map((el, index) => (
                                <Text key={index}>{el.name}</Text>
                            ))}

                            <Text fontWeight='bold'>Статус проверки:</Text>
                            <Text>{data.status || "none"}</Text>

                            <Text fontWeight='bold'>Причина отклонения:</Text>
                            <Text>{data.reason || "none"}</Text>
                        </Stack>
                    </CardBody>
                </Card>

                <Flex>
                    <Button onClick={() => getImg()}>Фото чека</Button>
                </Flex>

                <Flex direction='column' gap='15px'>
                    {show && <Image src={img} alt='No check image'></Image>}

                    <Menu>
                        <MenuButton w='max-content' as={Button} rightIcon={<ChevronDownIcon />}>
                            {value}
                        </MenuButton>

                        <MenuList onClick={(e) => setValue(e.target.textContent)}>
                            <MenuItem>Некорректные данные чека</MenuItem>
                            <MenuItem>Нет акционного товара</MenuItem>
                            <MenuItem>Чек не прошел проверку ФНС</MenuItem>
                            <MenuItem>Данные чека нечитаемые</MenuItem>
                            <MenuItem>Пользователь не выполнил условия проведения акции</MenuItem>
                            <MenuItem>Другое</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>

                <Stack spacing={4} direction='row' align='center'>
                    <Button colorScheme='green' size='lg' onClick={verifyCheck}>
                        Принять
                    </Button>

                    <Button colorScheme='red' size='lg' onClick={notVerifyCheck}>
                        Отклонить
                    </Button>
                </Stack>
            </Flex>
        </>
    );
};

export default CheckComponent;
