/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra Icons
import { BellIcon, EmailIcon, PhoneIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
    Button,
    Flex,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverTrigger,
    Portal,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import routes from "routes.js";
import AuthService from "../../services/AuthService";

export default function HeaderLinks(props) {
    const { variant, children, fixed, secondary, onOpen, email, setIsAuth, ...rest } = props;

    // Chakra Color Mode
    let mainTeal = useColorModeValue("teal.300", "teal.300");
    let inputBg = useColorModeValue("white", "gray.800");
    let mainText = useColorModeValue("gray.700", "gray.200");
    let navbarIcon = useColorModeValue("gray.500", "gray.200");
    const textColor = useColorModeValue("gray.700", "white");
    let searchIcon = useColorModeValue("gray.700", "gray.200");
    // const { isOpen, onOpen, onClose } = useDisclosure()
    const modalRef = React.useRef();
    const [isOpen, setIsOpen] = useState(false);
    if (secondary) {
        navbarIcon = "white";
        mainText = "white";
    }
    const settingsRef = React.useRef();

    async function onLogout() {
        const res = await AuthService.logout();
        localStorage.removeItem("auth");
        setIsAuth(false);
        setIsOpen(false);
    }
    return (
        <Flex pe={{ sm: "0px", md: "16px" }} w={{ sm: "100%", md: "auto" }} alignItems='center'>
            {/* <InputGroup
        cursor="pointer"
        bg={inputBg}
        borderRadius="15px"
        w={{
          sm: "128px",
          md: "200px",
        }}
        me={{ sm: "auto", md: "20px" }}
        _focus={{
          borderColor: { mainTeal },
        }}
        _active={{
          borderColor: { mainTeal },
        }}
      >
        <InputLeftElement
          children={
            <IconButton
              bg="inherit"
              borderRadius="inherit"
              _hover="{}"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<SearchIcon color={searchIcon} w="15px" h="15px" />}
            ></IconButton>
          }
        />
        <Input
          fontSize="xs"
          py="11px"
          color={mainText}
          placeholder="Type here..."
          borderRadius="inherit"
        />
      </InputGroup> */}
            {/* <NavLink to="/auth/signin">
        <Button
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant="transparent-with-icon"
          leftIcon={
            <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
          }
        >
          <Text display={{ sm: "none", md: "flex" }}>Sign In</Text>
        </Button>
      </NavLink> */}
            <Popover placement='bottom-start'>
                <PopoverTrigger>
                    <Button
                        ms='0px'
                        px='0px'
                        me={{ sm: "2px", md: "16px" }}
                        color={navbarIcon}
                        variant='transparent-with-icon'
                        leftIcon={<ProfileIcon color={navbarIcon} w='22px' h='22px' me='0px' />}
                    >
                        <Text display={{ sm: "none", md: "flex" }}>Администратор</Text>
                    </Button>
                </PopoverTrigger>
                <Portal>
                    <PopoverContent>
                        {/* <PopoverArrow />
            <PopoverHeader>Header</PopoverHeader> */}
                        {/* <PopoverCloseButton /> */}
                        <PopoverBody pt='15px'>
                            <Text color='gray.400'>
                                <EmailIcon /> {email}
                            </Text>
                        </PopoverBody>
                        <PopoverFooter display='flex' justifyContent='end'>
                            <Button colorScheme='red' onClick={() => setIsOpen(true)}>
                                Выйти
                            </Button>
                        </PopoverFooter>
                    </PopoverContent>
                </Portal>
            </Popover>

            <Modal
                finalFocusRef={modalRef}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalBody mt='30px'>
                        <Text
                            fontSize='sl'
                            color={textColor}
                            // fontWeight="bold"
                            textAlign='start'
                            mb='10px'
                        >
                            Вы уверены, что хотите выйти из аккаунта?
                        </Text>
                    </ModalBody>
                    <ModalFooter flexDirection='row-reverse' justifyContent='end'>
                        <Button colorScheme='red' mr={-1} onClick={onLogout}>
                            Выйти
                        </Button>
                        <Button
                            colorScheme='blue'
                            mr={-1}
                            onClick={() => setIsOpen(false)}
                            marginRight='20px'
                        >
                            Отмена
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <SidebarResponsive
                logoText={props.logoText}
                secondary={props.secondary}
                routes={routes}
                // logo={logo}
                {...rest}
            />
            <SettingsIcon
        cursor="pointer"
        me="16px"
        ref={settingsRef}
        onClick={props.onOpen}
        color={navbarIcon}
        w="18px"
        h="18px"
      />
      {/* <Menu>
        <MenuButton>
          <BellIcon color={navbarIcon} w="18px" h="18px" />
        </MenuButton>
        <MenuList p="16px 8px">
          <Flex flexDirection="column">
            <MenuItem borderRadius="8px" mb="10px">
              <ItemContent
                time="13 minutes ago"
                info="from Alicia"
                boldInfo="New Message"
                aName="Alicia"
                aSrc={avatar1}
              />
            </MenuItem>
            <MenuItem borderRadius="8px" mb="10px">
              <ItemContent
                time="2 days ago"
                info="by Josh Henry"
                boldInfo="New Album"
                aName="Josh Henry"
                aSrc={avatar2}
              />
            </MenuItem>
            <MenuItem borderRadius="8px">
              <ItemContent
                time="3 days ago"
                info="Payment succesfully completed!"
                boldInfo=""
                aName="Kara"
                aSrc={avatar3}
              />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu> */}
        </Flex>
    );
}

HeaderLinks.propTypes = {
    variant: PropTypes.string,
    fixed: PropTypes.bool,
    secondary: PropTypes.bool,
    onOpen: PropTypes.func,
};
